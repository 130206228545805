@media (min-width: 992px) {
  .modal-print {
    --bs-modal-width: 1000px !important;
  }
}

.red-text {
  color: red;
}

.bold {
  font-weight: bold;
}

.my-small-input {
  padding: 0rem 0.2rem !important;
  /* Ajusta el relleno según sea necesario */
  font-size: 9px !important;
  /* Reducir el tamaño de la fuente */
}

.col-form-label {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.form-check-label {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

/*Input con label flotante*/
.form-floating > .form-control.form-print,
.form-floating > .form-control-plaintext.form-print,
.form-floating > .form-select.form-print {
  height: 17px !important;
  min-height: 17px !important;
  line-height: 1px !important;
}

.my-small-floating-input.form-print {
  font-size: 11px !important;
  padding: 7px 2px 0px 2px !important;
  background-color: var(--bs-component-disabled-bg) !important;
}

.my-small-label-floating-input {
  font-size: 9px !important;
  padding: 0px !important;
  top: 4px !important;
  left: 0px !important;
  background-color: transparent;
}

.my-medium-floating-input.form-print {
  font-size: 11px !important;
  padding: 28px 2px 0px 2px !important;
  background-color: var(--bs-component-disabled-bg) !important;
}

.my-medium-floating-textarea.form-print {
  font-size: 11px !important;
  padding: 0px 1px 0px 1px !important;
  background-color: var(--bs-component-disabled-bg) !important;
}

.my-medium-label-floating-input {
  font-size: 9px !important;
  padding: 0px !important;
  top: 0px !important;
  left: 0px !important;
  background-color: transparent;
}

.my-small-label-floating-input-important {
  color: red !important;
  font-weight: bold !important;
}

.input-group.smaller input.form-control.form-print {
  height: 17px !important;
  /* Tamaño de fuente más pequeño */
  /* Altura más pequeña */
}

#print .form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: transparent !important;
}

#print .row > * {
  padding-left: 2px;
  padding-right: 2px;
  margin-bottom: 2px;
}

#print .form-check .form-check-input {
  margin-left: 0px !important;
  margin-right: 2px !important;
}

#print .floating-grouped-icon-right {
  padding-top: 4px !important;
  padding-left: 2px !important;
  color: #7a7a7a !important;
  font-size: 8px !important;
}

#print .floating-grouped-icon-left {
  padding-top: 3px !important;
  margin-left: 4px !important;
  color: #7a7a7a !important;
  font-size: 8px !important;
}

#print .form-check-label {
  font-size: 12px !important;
}

#print .form-control {
  border-radius: 3px !important;
  border: 0.5px solid var(--bs-component-border-color) !important;
}

#print .simple-text {
  line-height: 11px !important;
  font-size: 11px !important;
}

#print .panel-title {
  font-size: 11px !important;
}

#print .form-label {
  margin-bottom: 0px !important;
}

#print .input-group-text {
  padding: 0px 2px 0px 2px !important;
  font-size: 11px !important;
}

#print .invoice {
  padding-top: 5px !important;
}

#print .invoice .invoice-price .invoice-price-right {
  padding: 5px 20px 5px 20px !important;
}

#print .my-small-floating-input-without-label {
  font-size: 11px !important;
  padding: 0px 0px 0px 2px !important;
  background-color: var(--bs-component-disabled-bg) !important;
}

/*Montos*/
.table.form-print > :not(caption) > * > * {
  padding: 1px 1px 1px 1px !important;
}
